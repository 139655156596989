.root-magicsnakes-display {
  letter-spacing: 5px;
  color: white;
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.root-magicsnakes-display {
  color: white;
  margin: 0;
  font-family: 'trebuc', sans-serif;
  letter-spacing: 5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 36px;
  -webkit-tap-highlight-color: transparent;
}

.root-magicsnakes-display * {
  letter-spacing: 5px;
  font-family: 'trebuc', sans-serif;
}
