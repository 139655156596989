.root {
  width: 100%;
  height: 100%;
  display: flex;
  color: black;
  flex-direction: column;
  align-items: center;
}

.body {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  z-index: 1;
}

.titleContainer {
  padding: 40px;
  margin-top: -200px;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  color: white;
}

.title {
  font-weight: bold;
  font-size: 64px;
}
