.hudRoot {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hudPlayerContainer {
  position: absolute;
  animation: hudPlayerNameEnter 0.5s ease-in-out forwards;
  opacity: 0;
  transition: top 0.5s, left 0.5s;
}

@keyframes hudPlayerNameEnter {
  0% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.hudPlayerNamePart {
  max-width: 70%;
  flex-grow: 0;
  flex-shrink: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.hudPlayerScorePart {
  width: 30%;
  flex-grow: 0;
  flex-shrink: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

.go {
  color: black;
  font-size: 200px;
  animation: goEnter 1s ease-in-out forwards;
}

@keyframes goEnter {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  25% {
    transform: scale(1);
    opacity: 1;
  }
  75% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.winnerAnnouncement {
  font-size: 50px;
  animation: winnerAnnouncementEnter 3s ease-in-out forwards;
  background: rgba(0, 0, 0, 0.1);
  padding: 24px;
  border-radius: 8px;
}

@keyframes winnerAnnouncementEnter {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  25% {
    transform: scale(1);
    opacity: 1;
  }
  75% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.roundAnnouncementContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.threeTwoOne {
  color: black;
  font-size: 200px;
  animation: winnerAnnouncementEnter 1s ease-in-out forwards;
}
